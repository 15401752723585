import cross from "../../assets/images/cross.svg"
import deposit from "../../assets/images/deposit.svg"
import { useEffect, useState } from "react"
import axios from "axios";
import { BASE_URL } from "../../constanta/apiConfig";
import io from 'socket.io-client';
import { showDone, showError, showExpiredDeposit } from "../../constanta/Toastr";
import React from "react";

function DepositSecond({close, pays, summ, login, timer, setTimer, req, summDepositSec}){

    const activePay = pays.find(pay => pay.active);
    const [openFaqBtns, setFaqBtns] = useState({basic: false, balance: false})
    const [summa, setSumm] = useState('')




    useEffect(()=>{
            console.log(activePay)
            let changeSumm = summDepositSec.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            setSumm(changeSumm)
            const socketTimerDeposit = io(`${BASE_URL}`);
            socketTimerDeposit.on(login+':deposit_timer', (data) => {
                setTimer({minutes: data.minutes, seconds: data.seconds})
                if(data.close){
                    close()
                    showExpiredDeposit()
                }if(data.done){
                    close()
                    showDone('Ваш платеж был успешно зачислен!')
                }
            })

            return () => {
                socketTimerDeposit.off(login+':deposit_timer');
                socketTimerDeposit.disconnect();
            };
    }, [])

    

    const cancelPay = () => {
        close()
        axios.post(`${BASE_URL}/api/cancel-timer-deposit`, {}, {withCredentials: true})
        .then(res=>{
            if(res.data.status == 'ok'){
                showDone('Заявка на пополнение была отменена')
            }
        })
        // if(summ.length == 0){
        //     toastr.error("Уведите суму депозита")
        // }else{
        //     axios.post(`${BASE_URL}/api/create-deposit`,{
        //         summa:summ,
        //         type: activePay.type,
        //         name: activePay.name
        //     }, { withCredentials: true })
        //     .then(res => {
        //         if(res.data.status == "ok"){
        //             toastr.success("Ваша заявка прийнята <br>Ожидайте подтверждения оплаты")
        //         }else if(res.data.status == "error min"){
        //             toastr.info(`Минимальная сумма для пополнения ${res.data.min_data} руб`)
        //         }
                
        //     })
        // }
    }

    return (
        <div class="modal modal__deposit">
        <div class="modal__body modal__body_w_720">
            <img class="modal__cross" src={cross} alt="cross" onClick={close}/>
            <div class="modal__title">
                <span class="modal__title-text">Пополнение баланса</span>
                <span class="modal__title-line"></span>
            </div>
            <div class="modal__blocks">
                    <div className={"modal__block modal__deposit-block modal__block_active mx-w-none"}>
                                <img className={"modal__block-img "+(activePay.type == 'card' ? 'modal__block-img_w_140' : '')+(activePay.type == 'sbp' ? 'modal__block-img_w_48' : '')}
                                 src={require('../../assets/images/'+activePay.img)} alt={activePay.name}/>
                                <p class="modal__block-text">
                                    {activePay.name}
                                </p>
                    </div>
                
            </div>

            <label class="label modal__label">Осталось времени для оплаты</label>
            <div class="modal__input-wrapper modal__input-wrapper-time">
                <input class="input modal__input modal__input-sum modal__deposit-input-sum" type="text"
                    readonly=""
                    value={`${timer.minutes}:${timer.seconds}`}/>
            </div>

            <label class="label modal__label">Сумма</label>
            <div class="modal__input-wrapper modal__input-wrapper-sum">
                <input class="input modal__input modal__input-sum modal__deposit-input-sum" type="text"
                    readonly="" value={summa}/>
            </div>
            {
                (req.startsWith('http://') || req.startsWith('https://')) && (activePay.type === 'card' || activePay.type === 'sbp') ? (
                <a className="btn btn_background_blue modal__btn modal__btn-deposit" href={req} target="_blank" rel="noopener noreferrer">
                    Перейти к оплате
                </a>
                ) : (
                <React.Fragment>
                    <label className="label modal__deposit-label modal__label">{activePay.name}</label>
                    <input className="input modal__deposit-input" type="text" value={req} readOnly />
                </React.Fragment>
                )
            }
            
            <a class="btn btn_background_blue modal__btn modal__btn-deposit btn_red_deposit" style={{'marginBottom': 0}}
                onClick={cancelPay}
                href="#">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.25" fill-rule="evenodd" clip-rule="evenodd" d="M2.36899 6.54184C2.65912 4.34504 4.34504 2.65912 6.54184 2.36899C8.05208 2.16953 9.94127 2 12 2C14.0587 2 15.9479 2.16953 17.4582 2.36899C19.655 2.65912 21.3409 4.34504 21.631 6.54184C21.8305 8.05208 22 9.94127 22 12C22 14.0587 21.8305 15.9479 21.631 17.4582C21.3409 19.655 19.655 21.3409 17.4582 21.631C15.9479 21.8305 14.0587 22 12 22C9.94127 22 8.05208 21.8305 6.54184 21.631C4.34504 21.3409 2.65912 19.655 2.36899 17.4582C2.16953 15.9479 2 14.0587 2 12C2 9.94127 2.16953 8.05208 2.36899 6.54184Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L12 10.5858L14.2929 8.29289C14.6834 7.90237 15.3166 7.90237 15.7071 8.29289C16.0976 8.68342 16.0976 9.31658 15.7071 9.70711L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L10.5858 12L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289Z" fill="white"/>
                </svg>

                Отменить
            </a>
            <div class="faq__block" style={{'marginTop': 8+'px'}} onClick={()=>setFaqBtns({basic: !openFaqBtns.basic, balance: false})}>
                <div class="faq__block-wrapper block_deposit"><div class="faq__block-header">Как оплатить?</div><svg className={"faq__block-arrow "+(openFaqBtns.basic ? "faq__block-arrow_active" : '')} xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"><path d="M13 1.5L7.70711 6.79289C7.31658 7.18342 6.68342 7.18342 6.29289 6.79289L1 1.5" stroke="white" stroke-width="2" stroke-linecap="round"></path></svg></div>
                <span className={"faq__block-body "+(openFaqBtns.basic ? "faq__block-body_active" : "")} style={{'color': '#fff', 'padding': 8+'px'}}>{activePay.sup_text}</span>
            </div>
            <div class="faq__block" style={{'marginTop': 8+'px'}} onClick={()=>setFaqBtns({basic: false, balance: !openFaqBtns.balance})}>
                <div class="faq__block-wrapper block_deposit"><div class="faq__block-header">Не поступил платеж?</div><svg className={"faq__block-arrow "+(openFaqBtns.balance ? "faq__block-arrow_active" : '')} xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"><path d="M13 1.5L7.70711 6.79289C7.31658 7.18342 6.68342 7.18342 6.29289 6.79289L1 1.5" stroke="white" stroke-width="2" stroke-linecap="round"></path></svg></div>
                <span className={"faq__block-body "+(openFaqBtns.balance ? "faq__block-body_active" : "")} style={{'color': '#fff', 'padding': 8+'px'}}>Оплата поступает в автоматическом режиме. Если оплата не поступила в течение 15 минут, обратитесь в техническую поддержку.</span>
            </div>
        </div>
    </div>
    )
}

export default DepositSecond;